/* eslint-disable */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';

import { useForm } from 'react-hook-form';
import { UIContext } from '../../context/UIContext';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

import DialogMessage from '../../shared/DialogMessage';

import error_icon from './../../assets/img/icon_error.png';
import confirm_icon from './../../assets/img/icon-confirm.png';
import pay_circle from '../../assets/img/pay_circle.png';
import complete_icon from './../../assets/img/icon_complete.png';
import StepperContainer from './components/stepper/StepperContainer';
import { formatCurrency } from '../../utils/commonMethods';
import Snackbar from '@mui/material/Snackbar';
import { Alert, AlertTitle, Slide } from '@mui/material';

const CONFIRM_DIALOG_TEXT = (
  <p>
    Are you sure you want to submit this Notice to Vacate form? A copy of this form will be sent to
    the registered email address of<span className="font-bold"> all residents</span>. <br></br>
    <br></br> After the community has processed your Notice, you will see the status updated in{' '}
    <span className="font-bold"> My Lease Dashboard</span> in Lease Manager. Please{' '}
    <span className="font-bold">allow up to two business days</span> for this status update to
    display. We will record your Notice to Vacate based on the day you submitted this form.
  </p>
);

const CONFIRM_PAYMENT_TEXT = (fee) => {
  return (
    <div>
      <p>
        Your offer to change the Lease End Date is{' '}
        <span className="font-bold text-[#F5222D]">pending</span> receipt of your{' '}
        <span className="font-bold"> Early Termination Charge payment within 24 hours.</span> If you
        fail to make payment, your Notice to Vacate will still be processed and handled as "Remain
        Rent Responsible."{' '}
      </p>
      <div className="pt-4 pb-4">
        <span className="font-bold">To pay your Early Termination Charge:</span>
        <ul className="list-disc ml-5">
          <li>
            Select <span className="font-bold"> Pay my Early Termination Charge</span> button below
            to enter AvalonAccess
          </li>
          <li>
            Select{' '}
            <span className="font-bold">One Time Payment or One Time Credit Card Payment</span>
          </li>
          <li>
            Select <span className="font-bold"> Other Amount</span>
          </li>
          <li>
            Enter <span className="font-bold text-[#06A7D8]">${formatCurrency(fee)} </span> and
            submit the payment
          </li>
        </ul>
        <span className="italic">
          The charge to offset your current payment will appear within two business days in
          AvalonAccess.
        </span>
      </div>
      <p className="italic xs:text-tiny  md:text-[14px] pt-1 pb-3">
        *For the Early Termination Charge to be accepted, there must be no outstanding balances due
        on your account, and your account must be in good legal standing.
      </p>
    </div>
  );
};

const SUCCESS_MESSAGE = (
  <p>
    A copy of this Notice to Vacate form has been sent to the registered email address of all
    residents.
  </p>
);

const ERROR_NOT_EMAIL_FOUND = (
  <p>
    Sorry! You cannot submit this form. One of the resident email addresses is blank. This is needed
    to copy all residents on submission of this form. Please reach out to the community for help to
    provide notice of your intent to vacate.
  </p>
);

const NoticeToVacateForm = ({ isOpen, handleCurrentStep, currentStep }) => {
  const {
    register,
    control,
    handleSubmit,
    clearErrors,
    reset,
    getValues,
    resetField,
    watch,
    setValue,

    formState: { errors, dirtyFields }
  } = useForm({
    mode: 'onChange'
  });

  const {
    formData,
    openMessageDialog,
    messageDialogOpen,
    setFormNoticeToVacate,
    showSuccessMessage,
    showConfirmationPaymentMessage,
    closeMessageDialog,
    openPaymentDialog,
    moveOutInformationRes,
    forwardingAddressRes
  } = useContext(UIContext);

  const { resident, community, coresidents, occupants, lease } = formData;

  const [disableForm, setDisableForm] = useState();
  const [residentsCoResidentsOccupants, setResidentsCoResidentsOccupants] = useState(1);

  const [messageDialog, setMessageDialog] = useState({
    error: false,
    confirm: false,
    confirmLeaseTermination: false,
    success: false,
    snackbarMessage: false
  });

  const initialStateSnackBar = {
    vertical: 'top',
    horizontal: 'right',
    open: true
  };

  const [snackState, setSnackState] = React.useState(initialStateSnackBar);
  const { vertical, horizontal, open } = snackState;

  const modIsBeforeLED = () => {
    const moveOutDateUtc = dayjs.utc(moveOutInformationRes?.moveOutDate).startOf('day');
    const leaseEndDateUtc = dayjs.utc(formData?.lease?.leaseExpirationDate).startOf('day');
    return (
      moveOutDateUtc.isBefore(leaseEndDateUtc) &&
      moveOutInformationRes?.leaseTerminationOption?.id === 1
    );
  };
  const prepareData = () => {
    const data = getValues();

    const validForm = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value != null && value !== '')
    );

    const { isForwardingAddressUnknown } = validForm;

    const trackFormSubmittedPendo = (form) => {
      if (window.pendo && window.pendo.track) {
        try {
          window.pendo.track('NTVFormSubmited', form);
        } catch (error) {
          console.error('Error tracking with Pendo:', error);
        }
      } else {
        console.warn('Pendo is not defined or the track function is not available.');
      }
    };

    const {
      moveOutDate,
      transferToAnotherCommunity,
      leaseTerminationOption,
      reasonMoveOut,
      residenceTypeMoveIn,
      street,
      city,
      countrySelected,
      addressLine2,
      state,
      zip,
      feedback,
      signature
    } = validForm;

    const addTerminationOpt = () => {
      const { earlyTermination, terminationOptions } = formData;
      const hasValidTerminationFees = earlyTermination?.fees?.some(item => item.fee > 0);
    
      const id = (earlyTermination?.fees?.length > 0 && !hasValidTerminationFees)
        ? terminationOptions?.find(term => term.id === 2)?.id
        : leaseTerminationOption?.id;
    
      return id;
    };
    

    let formToSubmit = {
      moveOutDate: dayjs(moveOutDate).format(),
      reasonMoveOut: reasonMoveOut.id,
      residenceTypeMoveIn: residenceTypeMoveIn.id,
      feedback: feedback ? feedback.trim() : feedback,
      signature: signature?.trim(),
      userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      submitTsDate: dayjs().format(),
      earlyTerminationMoveOutStartDate: dayjs(
        moveOutInformationRes?.selectedFee?.period?.moveOutStart
      ).format(),
      earlyTerminationMoveOutEndDate: dayjs(
        moveOutInformationRes?.selectedFee?.period?.moveOutEnd
      ).format(),
      earlyTerminationFee:
        moveOutInformationRes?.selectedFee?.fee === 0
          ? null
          : moveOutInformationRes?.selectedFee?.fee,
      transferOptionId: transferToAnotherCommunity?.id,
      terminationOptionId: transferToAnotherCommunity?.id !== 1 ? undefined : addTerminationOpt()
    };
    if (!forwardingAddressRes.isForwardingAddressUnknown) {
      const address = {
        street: forwardingAddressRes?.street?.trim(),
        city: forwardingAddressRes?.city?.trim(),
        country: forwardingAddressRes?.countrySelected.countryName,
        state: forwardingAddressRes?.state?.trim(),
        zip: forwardingAddressRes?.zip?.trim()
      };
      if (countrySelected.countryCode !== 'US') {
        address.addressLine2 = addressLine2 && addressLine2?.trim();
      }

      setFormNoticeToVacate({ ...formToSubmit, address });
      trackFormSubmittedPendo({ ...formToSubmit, address });
    } else {
      setFormNoticeToVacate(formToSubmit);
      trackFormSubmittedPendo(formToSubmit);
    }
  };

  const onSubmit = () => {
    prepareData();
    openMessageDialog();
    setMessageDialog({
      error: false,
      confirm: true,
      success: false,
      confirmLeaseTermination: false
    });
  };

  const checkEmailsForResidentsAndOccupants = () => {
    const residentsAndCoResidents = [...coresidents, resident];
    const isEmptyObject = (obj) => obj && Object.keys(obj).length === 0;
    const invalidEmails = residentsAndCoResidents
      .flat()
      .map((resident) => resident?.email == null && !isEmptyObject(resident));

    const hasValidsEmail = invalidEmails.every(
      (value) => value === false && resident.email !== null
    );

    setDisableForm(!hasValidsEmail);
    if (!hasValidsEmail) {
      openMessageDialog();
      setMessageDialog({
        confirm: false,
        error: true,
        success: false,
        confirmLeaseTermination: false
      });
    } else {
      closeMessageDialog();
      setMessageDialog({
        confirm: false,
        error: false,
        success: false,
        confirmLeaseTermination: false
      });
    }
  };

  const countResidentsCoresidentsOccupants = () => {
    const residentsCoResidentsOccupants = [...coresidents, ...occupants, resident];
    setResidentsCoResidentsOccupants(residentsCoResidentsOccupants.length);
  };

  const handleLeaseOptDialog = () => {
    openMessageDialog();
    setMessageDialog({
      error: false,
      confirm: false,
      success: false,
      snackbarMessage: true,
      confirmLeaseTermination: true
    });
  };

  const handleSnackbarClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackState({ ...initialStateSnackBar, open: false });
  };

  useEffect(() => {
    if (moveOutInformationRes?.transferToAnotherCommunity?.id === 1 && modIsBeforeLED()) {
      setSnackState({ ...initialStateSnackBar, open: true });

      setMessageDialog({
        confirm: false,
        error: false,
        success: false,
        confirmLeaseTermination: false,
        snackbarMessage: true
      });
      return openPaymentDialog();
    }
    if (showSuccessMessage)
      return setMessageDialog({
        confirm: false,
        error: false,
        success: true,
        confirmLeaseTermination: false
      });
    if (coresidents && coresidents.length > 0) {
      checkEmailsForResidentsAndOccupants();
      countResidentsCoresidentsOccupants();
    }
  }, [showSuccessMessage, resident]);

  useEffect(() => {
    if (showConfirmationPaymentMessage) {
      handleLeaseOptDialog();
    }
  }, [showConfirmationPaymentMessage]);

  return (
    <div className=" bg-transparent xl:border-0 xl:p-0 rounded-xl">
      <form onSubmit={handleSubmit(onSubmit)} aria-label="form">
        <div className={`lg:w-full lg:static md:z-0  md:w-[90%] md:absolute`}>
          <div
            className={`xs:hidden md:block lg:hidden  md:w-[100px] ${currentStep !== 0 && 'xs:hidden md:block md:h-[0px] lg:hidden'}  ${isOpen ? 'md:h-[755px] ' : 'md:h-[375px] '}`}></div>

          <div>
            <StepperContainer
              community={community}
              residentsCoResidentsOccupants={residentsCoResidentsOccupants}
              isOpen={isOpen}
              coresidents={coresidents}
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
              leaseExpirationDate={lease?.leaseExpirationDate}
              disableForm={disableForm}
              resetField={resetField}
              reset={reset}
              clearErrors={clearErrors}
              watch={watch}
              dirtyFields={dirtyFields}
              handleCurrentStep={handleCurrentStep}
              onSubmit={onSubmit}
            />
          </div>
        </div>
      </form>
      {messageDialog.error && (
        <DialogMessage
          open={messageDialogOpen}
          icon={error_icon}
          title={'Email Not Found !'}
          body={ERROR_NOT_EMAIL_FOUND}
          confirmButton={'Got It!'}
        />
      )}
      {messageDialog.confirm && (
        <DialogMessage
          open={messageDialogOpen}
          icon={confirm_icon}
          title={'Confirm Notice To Vacate ?'}
          body={CONFIRM_DIALOG_TEXT}
          confirmButton={'Confirm'}
          cancelButton={'Cancel'}
          submitForm={
            moveOutInformationRes?.transferToAnotherCommunity?.id !== 1 || !modIsBeforeLED()
          }
          isTransferToAnotherCommunity={
            moveOutInformationRes?.transferToAnotherCommunity?.id !== 1 || !modIsBeforeLED()
          }
        />
      )}
      {messageDialog.confirmLeaseTermination && (
        <DialogMessage
          open={messageDialogOpen}
          icon={pay_circle}
          title={'Last Step to Change Lease End Date'}
          body={CONFIRM_PAYMENT_TEXT(moveOutInformationRes?.selectedFee?.fee)}
          confirmButton={'Pay my Early Termination Charge'}
          cancelButton={'Return to the Dashboard'}
          submitForm={false}
          isPaymentDialog={true}
        />
      )}
      {messageDialog.success && (
        <DialogMessage
          open={messageDialogOpen}
          icon={complete_icon}
          title={'Email Sent!'}
          body={SUCCESS_MESSAGE}
          confirmButton={'Got it'}
          goHome={true}
        />
      )}
      {messageDialog.snackbarMessage && (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          TransitionComponent={Slide}
          onClose={handleSnackbarClose}
          autoHideDuration={6000}
          key={vertical + horizontal}>
          <Alert
            onClose={handleSnackbarClose}
            icon={<img src={complete_icon} width={22} />}
            severity="success"
            sx={{ width: '100%' }}>
            <span className="font-semibold">Success!</span> Notice to Vacate submitted.
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default NoticeToVacateForm;
