import { formatCurrency } from '../../../../../utils/commonMethods';

export const TermsAndAgreementWithLeaseTermOpt = (amount) => {
  return (
    <div className="font-proxima md:text-sm">
      <div>
        <p>
          Should I subsequently wish to cancel or extend my notice to a later date, I will contact
          you immediately through a written request.If my apartment has already been re-rented to a
          new resident at the time I make such a request, I understand that it may be impossible for
          you (the owner&rsquo;s agent) to grant this request.
        </p>{' '}
        <p className="pt-4">
          I understand that upon rendering this notice, I am still liable under the conditions of my
          present Lease Agreement.
        </p>{' '}
        <p className="pt-4">
          If I do not submit payment for the Early Termination Charge of{' '}
          <span className="font-bold text-[#C64D5B]">${formatCurrency(amount)} </span>within 24
          hours, I acknowledge that my Notice to Vacate will be handled as “Remain Rent
          Responsible.”
        </p>
        <p className="pt-4">
          If I submit Early Termination payment after 24 hours or if I have a pending balance on my
          account at the time I submit this form, I understand that the payment may be applied to
          Rent due, and my Notice to Vacate may be handled as “Remain Rent Responsible.”
        </p>
        <p className="pt-4">
          A copy of this completed form with my selections will be sent to all resident email
          addresses listed in this form.
        </p>
      </div>
    </div>
  );
};

export const TermsAndAgreemet = () => {
  return (
    <div>
      <p>
        Should I subsequently wish to cancel or extend my notice to a later date, I will contact you
        immediately through a written request. If my apartment has already been re-rented to a new
        resident by that time I make such a request, I understand that it may be impossible for you
        (the owner&rsquo;s agent) to grant this request.
      </p>
      <p className="mt-5">
        I understand that upon rendering this notice, I am still liable under the conditions of my
        present Lease Agreement.
      </p>
    </div>
  );
};
