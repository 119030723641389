/* eslint-disable react/prop-types */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useContext, useState, useEffect } from 'react';
import { UIContext } from '../../../../../context/UIContext';
import StepButtons from '../../../../../shared/StepButtons';
import { TermsAndAgreementWithLeaseTermOpt, TermsAndAgreemet } from './TextTermsAndAgreement';

dayjs.extend(utc);

const TermsAndAgreement = ({
  register,
  errors,
  clearErrors,
  resetField,
  disableForm,
  setValue,
  handleSubmit,
  handleBack,
  watch,
  setIsValidStep
}) => {
  const {
    moveOutInformationRes,
    reasonToMoveOutRes,
    forwardingAddressRes,
    updateValidationForm,
    formData,
    termsRes
  } = useContext(UIContext);

  const [termsAgreementForm, setTermsAgreementForm] = useState({});

  const handleChange = (e) => {
    resetField('signature');
    e.target.checked && clearErrors('signature');
    setValue('signingOnBehalfAllOccupants', e.target.checked);
  };

  const updateForm = (data) => {
    const { signature, signingOnBehalfAllOccupants } = data;
    setTermsAgreementForm({
      signature,
      signingOnBehalfAllOccupants
    });
    updateValidationForm({
      moveOutInformationRes,
      reasonToMoveOutRes,
      forwardingAddressRes,
      termsRes: {
        signature,
        signingOnBehalfAllOccupants
      }
    });
  };

  const validateBtn = () => {
    const isValidForm =
      termsAgreementForm?.signingOnBehalfAllOccupants &&
      termsAgreementForm?.signature &&
      termsAgreementForm?.signature?.trim() !== '';

    setIsValidStep(isValidForm);
    return isValidForm;
  };

  const modIsBeforeLED = () => {
    const moveOutDateUtc = dayjs.utc(moveOutInformationRes?.moveOutDate).startOf('day');
    const leaseEndDateUtc = dayjs.utc(formData?.lease?.leaseExpirationDate).startOf('day');
    return (
      moveOutDateUtc.isBefore(leaseEndDateUtc) &&
      moveOutInformationRes?.leaseTerminationOption?.id === 1
    );
  };

  useEffect(() => {
    const subscription = watch((data) => {
      const updatedData = {
        ...termsRes,
        ...moveOutInformationRes,
        ...forwardingAddressRes,
        ...reasonToMoveOutRes,
        ...Object.keys(data).reduce((acc, key) => {
          if (data[key] !== undefined || typeof data[key] === 'object') {
            acc[key] = data[key];
          }
          return acc;
        }, {})
      };

      updateForm(updatedData);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, termsRes]);

  const prevState = () => {
    setTermsAgreementForm(termsRes);
  };

  useEffect(() => {
    termsRes && prevState();
  }, []);

  return (
    <div className="bg-white rounded w-11/12 lg:m-10 m-3 md:m-6 ">
      <div className=" font-proxima">
        <p className="font-proxima  text-sm font-semibold mb-1 md:text-[24px] md:font-medium">
          Terms & Agreement
        </p>
        <div className="text-tiny md:text-sm md:pt-2">
          {moveOutInformationRes?.transferToAnotherCommunity?.id === 1 && modIsBeforeLED()
            ? TermsAndAgreementWithLeaseTermOpt(moveOutInformationRes?.selectedFee?.fee)
            : TermsAndAgreemet()}
        </div>

        <div>
          <div className="flex items-center mt-5  md:mt-10">
            <input
              type="checkbox"
              id="signingOnBehalfAllOccupants"
              aria-label="signingOnBehalfAllOccupants"
              name="signingOnBehalfAllOccupants"
              disabled={disableForm}
              value={termsRes?.signingOnBehalfAllOccupants || false}
              style={{ color: '#C64D5B', borderColor: '#C64D5B' }}
              {...register('signingOnBehalfAllOccupants', { required: true })}
              onChange={(e) => {
                handleChange(e);
              }}
              className={
                errors?.signingOnBehalfAllOccupants
                  ? 'appearance-none h-4 w-4  border border-[#C64D5B]'
                  : `appearance-none h-4 w-4  border border-[#C64D5B]  checked:bg-[#C64D5B] text-[#C64D5B] focus:ring-0 disabled:bg-[#BABCC067]`
              }
            />
            <label className="ml-2 text-tiny md:text-sm ">
              <p className="font-semibold ">
                {' '}
                <span className="text-[#F5222D]">*</span> I am signing on behalf of all residents.
                All occupants agree to vacate the apartment by the move out date provided.
              </p>
            </label>
          </div>
          {errors?.signingOnBehalfAllOccupants && (
            <p className="text-[#F5222D] text-[10px] pt-2">
              Please select the button for agreement
            </p>
          )}
          <div className="text-xs md:text-sm mt-5">
            <label className="block text-sm font-bold mb-2">
              <p className="font-semibold text-xs md:text-sm">
                <span className="text-[#F5222D]">*</span> Electronic Signature{' '}
                <span className=" font-normal italic hidden md:inline-block">
                  (Enter your full legal name)
                </span>
              </p>
              <p className="font-normal  text-tiny italic  inline-block md:hidden">
                (Enter your full legal name)
              </p>
            </label>
            <input
              className={
                errors?.signature
                  ? ' appearance-none border border-[#F5222D] rounded w-full py-2 px-3 md:w-full  lg:w-[85%] text-gray-700 leading-tight focus:outline-none focus:shadow-outline '
                  : ' appearance-none border border-[#BABCC0] rounded w-full py-2 px-3 md:w-full  lg:w-[85%] text-gray-700 leading-tight focus:outline-none focus:shadow-outline disabled:bg-[#BABCC067] disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none'
              }
              id="signature"
              type="text"
              name="signature"
              value={termsRes?.signature || ''}
              {...register('signature', { required: true })}
              aria-label="signature"
              maxLength={100}
              disabled={termsRes?.signingOnBehalfAllOccupants == false}
              placeholder="Please Enter…"
            />
            {errors?.signature && (
              <p className="text-[#F5222D] text-[10px] pt-1">
                Please enter your electronic signature
              </p>
            )}
          </div>
          <div className="mt-5">
            <p className="font-semibold md:text-sm">
              <span className="text-[#F5222D]">*</span> Today’s Date
              <span className="font-normal italic hidden md:inline-block pl-1">
                (Submission Date)
              </span>
            </p>
            <span className="font-normal  text-tiny  italic md:hidden ">(Submission Date)</span>
            <div className="mt-2 ml-2">
              <p className="text-xs md:text-sm text-[#525761]">{dayjs().format('MM/DD/YYYY')}</p>
            </div>
          </div>
        </div>
      </div>
      <StepButtons
        isValidForm={validateBtn()}
        handleBack={handleBack}
        handleStep={handleSubmit}
        cancelText="Previous"
        confirmText="Submit"
      />
    </div>
  );
};

export default TermsAndAgreement;
